// Generated by Framer (85a580f)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ySe3VvZcL"];

const serializationHash = "framer-jbW5t"

const variantClassNames = {ySe3VvZcL: "framer-v-ulf0jr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ySe3VvZcL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jbW5t", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ulf0jr", className)} data-framer-name={"Test 1 "} layoutDependency={layoutDependency} layoutId={"ySe3VvZcL"} ref={ref ?? ref1} style={{backgroundColor: "rgb(33, 84, 138)", ...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jbW5t [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jbW5t .framer-1efkanv { display: block; }", ".framer-jbW5t .framer-ulf0jr { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 38px; height: 1169px; justify-content: flex-start; padding: 60px 50px 50px 50px; position: relative; width: 768px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jbW5t .framer-ulf0jr { gap: 0px; } .framer-jbW5t .framer-ulf0jr > * { margin: 0px; margin-bottom: calc(38px / 2); margin-top: calc(38px / 2); } .framer-jbW5t .framer-ulf0jr > :first-child { margin-top: 0px; } .framer-jbW5t .framer-ulf0jr > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1169
 * @framerIntrinsicWidth 768
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerHK_c4tTK9: React.ComponentType<Props> = withCSS(Component, css, "framer-jbW5t") as typeof Component;
export default FramerHK_c4tTK9;

FramerHK_c4tTK9.displayName = "Toggle Animation Tablet Copy";

FramerHK_c4tTK9.defaultProps = {height: 1169, width: 768};

addFonts(FramerHK_c4tTK9, [])